
export default{
	areaFullLevel4_5(id) { // 街道-社区
		return {
			value: 'id',
			label: 'regionName',
			lazy: true,
			lazyLoad: (node, resolve) => {
				this.$ajax({
					url: '/anony/pull/down/regionList',
					data: {levl: node.level + 4, parentId: (node.data && node.data.id) || id || this.$store.state.textConfig.regionCode},
					success: res => {
						res.data.forEach(item => {
							item.leaf = node.level >= 1;
						});

						resolve(res.data);
					}
				})
			}
		}
	},
	areaFullLevel4_4(id) { // 街道-社区
		return {
			value: 'id',
			label: 'regionName',
			lazy: true,
			lazyLoad: (node, resolve) => {
				this.$ajax({
					url: '/anony/pull/down/regionList',
					data: {levl: node.level + 4, parentId: (node.data && node.data.id) || id || this.$store.state.textConfig.regionCode},
					success: res => {
						res.data.forEach(item => {
							item.leaf = node.level >= 0;
						});

						resolve(res.data);
					}
				})
			}
		}
	},
	areaFullLevel1_4() { // 区-街道
		return {
			value: 'id',
			label: 'regionName',
			lazy: true,
			lazyLoad: (node, resolve) => {
				this.$ajax({
					url: '/anony/pull/down/regionList',
					data: {levl: node.level + 1, parentId: (node.data && node.data.id) || ''},
					success: res => {
						res.data.forEach(item => {
							item.leaf = node.level >= 3;
						});

						resolve(res.data);
					}
				})
			}
		}
	},
	areaFullLevel1_3() { // 省-市-区
		return {
			value: 'id',
			label: 'regionName',
			lazy: true,
			lazyLoad: (node, resolve) => {
				if(node.level <= 2) {
					this.$ajax({
						url: '/anony/pull/down/regionList',
						data: {levl: node.level + 1, parentId: (node.data && node.data.id) || null},
						success: res => {
							// console.log(res)
							res.data.forEach(item => {
								item.leaf = node.level >= 2;
							});

							resolve(res.data);
						}
					})
				}
			}
		}
	},
	areaFullLevel1_5() { // 省-市-区-街道-社区
		return {
			value: 'id',
			label: 'regionName',
			lazy: true,
			lazyLoad: (node, resolve) => {
				if(node.level <= 4) {
					this.$ajax({
						url: '/anony/pull/down/regionList',
						data: {levl: node.level + 1, parentId: (node.data && node.data.id) || null},
						success: res => {
							// console.log(res)
							res.data.forEach(item => {
								item.leaf = node.level >= 4;
							});

							resolve(res.data);
						}
					})
				}
			}
		}
	}
}