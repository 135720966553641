import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import dropDownList from './dropDownList' // 下拉
import areaFill from './areaFill' // 要素区域回填

Vue.use(Vuex)

const store = {
	state: {
		systemType: '',
		textConfig: {},
		systemTimer: '',
		token: localStorage.getItem('userToken') || '',
		imgUrl: process.env.VUE_APP_IMG_SRC,
		imgUrl1: process.env.VUE_APP_IMG_SRC2,
		userInfo: {},
		... dropDownList,
		... areaFill,
		showLoginBox: false, // 是否显示登录框
	},
	getters,
	mutations: {}
}


export default new Vuex.Store(store)