export default {
	maxQuota: [{ // 融资最高额度
		label: '50万元以下',
		value: 1
	}, {
		label: '50-300万元',
		value: 2
	}, {
		label: '300万元以上',
		value: 3
	}],
	financingTerm: [],
	financingStatus: [{ // 融资状态
		label: '待受理',
		value: 1
	}, {
		label: '待受理',
		value: 2
	}, {
		label: '已备案',
		value: 3
	}, {
		label: '风控状态',
		value: 4
	}, {
		label: '已结束',
		value: 5
	}, {
		label: '备案驳回',
		value: 6
	}],
	guaranteeMethod: [{ // 融资方式 担保方式
		label: '信用',
		value: 1
	}, {
		label: '抵押',
		value: 2
	}, {
		label: '保证',
		value: 3
	}, {
		label: '质押',
		value: 4
	}, {
		label: '组合',
		value: 5
	}],
	financingAuditStatus: [{ // 融资审核状态
		label: '待审核',
		value: 1
	}, {
		label: '待审核',
		value: 2
	}, {
		label: '拒绝受理',
		value: 3
	}, {
		label: '审核通过',
		value: 4
	}, {
		label: '审核拒绝',
		value: 5
	}],
	elementStatus: [{ // 要素审核状态
		label: '待审',
		value: 1
	}, {
		label: '通过',
		value: 2
	}, {
		label: '拒绝',
		value: 3
	}, {
		label: '未发起融资或流转',
		value: 4
	}],
	elementTypeList: [{ // 要素类型
		label: '融资要素',
		value: 1
	}, {
		label: '流转要素',
		value: 2
	}],
	applyPeopleTypeList: [ // 要素申请人类型 - 出让方类型
		{ label: '个人', value: 1 },
		{ label: '企业', value: 2 },
		{ label: '村集体', value: 3 },
		{ label: '其他类经营主体', value: 4 }
	],
	isFinancingList: [ // 要素是否融资
		{ label: '未融资', value: 0 },
		{ label: '已融资', value: 1 },
	],
	circulationMode: [ // 流转方式
		// {label: '全部', value: ''},
		{ label: '发包', value: 1 },
		// { label: '转让', value: 2 },
		{ label: '出租', value: 6 },
		{ label: '入股', value: 3 },
		{ label: '出让', value: 2 },
		{ label: '转包', value: 4 },
		{ label: '互换', value: 5 },
		{ label: '其他', value: 7 },
	],
	circulationModeAgain: [ // 再次流转方式、再次转出方式
		{ label: '转让', value: 1 },
		{ label: '入股', value: 2 },
		{ label: '转包', value: 3 },
		{ label: '互换', value: 4 },
		{ label: '出租', value: 5 }
	],
	circulationProjectStatus: [ // 流转项目状态
		// {label: '全部', value: ''},
		{ label: '待审', label2: '', label3: '', value: 1 },
		{ label: '通过(未开始)', label2: '', label3: '', value: 2 },
		{ label: '拒绝', label2: '', label3: '', value: 3 },
		{ label: '报名中', label2: '报名中', label3: '预竞价', value: 4 },
		{ label: '待成交', label2: '待成交', label3: '竞价中', value: 5 },
		{ label: '已结束', label2: '已结束', label3: '', value: 6 },
		{ label: '已成交', label2: '已成交', label3: '竞价完毕', value: 7 },
		{ label: '已公告中标', label2: '已公告中标', value: 8 },
		{ label: '已取消', label2: '', label3: '', value: 9 },
		{ label: '被终止', label2: '被终止', label3: '', value: 11 },
		{ label: '流转已核销', label2: '流转已核销', label3: '', value: 12 },
		{ label: '中止', label2: '中止', label3: '', value: 13 },
	],
	signUpStatus: [ // 流转项目报名状态-初审、复审
		// {label: '全部', value: ''},
		{ label: '待审', value: 1 },
		{ label: '通过', value: 2 },
		{ label: '拒绝', value: 3 },
		{ label: '已成交', value: 4 }
	],
	paymentMethod: [ // 付款方式
		// {label: '全部', value: ''},
		{ label: '一次性付清', value: 1 },
		{ label: '分期付款', value: 2 },
	],
	paymentMethodCirculation: [ // 付款方式-流转
		// {label: '全部', value: ''},
		{ label: '分期付款', value: 0 },
		{ label: '一次性付清', value: 1 },
	],
	dealMethod: [ // 交易方式
		// {label: '全部', value: ''},
		{ label: '竞价', value: 1 },
		{ label: '拍卖', value: 2 },
		{ label: '协议', value: 3 },
		{ label: '招标', value: 4 }
	],
	moneyMethod: [ // 缴费方式
		{ label: '现金汇款', value: 1 },
		{ label: '支票', value: 2 },
		{ label: '汇票', value: 3 },
		{ label: '电汇', value: 4 },
	],
	listingExpiredMethod: [ // 挂牌期满处理方式
		{ label: '信息发布终结', value: 1 },
		{ label: '延长发布信息', value: 2 },
		{ label: '变更公告内容重新挂牌', value: 3 }
	],
	subjectMatterType: [ // 标的类型
		{ label: '建筑物', value: 1 },
		{ label: '房屋', value: 2 },
		{ label: '苗', value: 3 },
		{ label: '股权', value: 4 },
		{ label: '种畜禽', value: 5 },
		{ label: '交通运输工具', value: 6 },
		{ label: '商铺', value: 7 },
		{ label: '其他', value: 8 },
	],
	ownershipType: [ // 所有权人信息
		{ label: '村集体', value: 1 },
		{ label: '国有', value: 2 },
		{ label: '集体', value: 3 },
		{ label: '合作社', value: 4 },
		{ label: '自然人', value: 5 }
	],
	quantityUnit: [ // 数量单位
		{ label: '辆', value: 11 },
		{ label: '米', value: 12 },
		{ label: '个', value: 13 },
		{ label: '平方米', value: 2 },
		{ label: '眼', value: 14 },
		{ label: '亩', value: 10 },
		{ label: '口', value: 15 },
		{ label: '套', value: 6 },
		{ label: '间', value: 16 },
		{ label: '棵', value: 22 },
		{ label: '盏', value: 17 },
		{ label: '台', value: 8 },
		{ label: '斤', value: 18 },
		{ label: '公斤', value: 19 },
		{ label: '头', value: 20 },
		{ label: '座', value: 21 }
	],
	assetEquity: [ // 资产权益
		{ label: '使用权', value: 1 },
		{ label: '所有权', value: 2 },
		{ label: '租赁权', value: 3 },
		{ label: '收益权', value: 4 },
	],
	assetManagement: [ // 资产经营类型
		{ label: '经营性', value: 1 },
		{ label: '资源性', value: 2 },
		{ label: '资产类', value: 3 },
		{ label: '权证类', value: 4 }
	],
	educationList: [ // 学历
		{ label: '不限', value: 1 },
		{ label: '初中及以下', value: 2 },
		{ label: '中专/中技', value: 3 },
		{ label: '高中', value: 4 },
		{ label: '大专', value: 5 },
		{ label: '本科', value: 6 },
		{ label: '硕士', value: 7 },
		{ label: '博士', value: 8 },
	],
	investmentReviewStatus: [ // 招商项目审核状态
		{ label: '待审批', value: 0 },
		{ label: '审批拒绝', value: 1 },
		{ label: '审批通过', value: 2 }
	],
	investmentProjectStatus: [ // 招商项目状态
		{ label: '未开始', value: 1 },
		{ label: '报名中', value: 2 },
		{ label: '进行中', value: 3 },
		{ label: '终止', value: 4 },
		{ label: '完成', value: 5 }
	],
	investmentSignUpStatus: [ // 招商项目报名状态
		{ label: '待审批', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '驳回', value: 3 }
	],
	purchaseArea: [ // 求购面积
		{ label: '5亩以下', value: 1 },
		{ label: '5-50亩', value: 2 },
		{ label: '50-100亩', value: 3 },
		{ label: '100亩以上', value: 4 }
	],
	purposes: [ // 流转用途
		{ label: '耕地', value: 1 },
		{ label: '林地', value: 2 },
		{ label: '园地 ', value: 3 },
		{ label: '养殖用地', value: 4 },
		{ label: '水域商及住宅', value: 5 },
		{ label: '旱地', value: 6 },
		{ label: '水果地', value: 7 }
	],
	useType: [ // 要素使用类型
		{ label: '建设用地', value: 1 },
		{ label: '生产用地', value: 2 },
		{ label: '宅基地 ', value: 3 },
		{ label: '其他要素', value: 4 }
	],
	bidStatus: [ // 竞价状态
		// { label: '待审核', value: 1 },
		// { label: '未开始', value: 2 },
		// { label: '竞价中 ', value: 3 },
		{ label: '未开始', value: 4 },
		{ label: '竞价中', value: 5 },
		{ label: '已结束', value: 6 },
		{ label: '已成交', value: 7 },
		{ label: '已成交', value: 8 },
		{ label: '已取消', value: 9 },
		{ label: '已终止', value: 11 }
	],
	recruitSalary: [ // 招聘薪资待遇
		{ label: '1k-2k', value: 1 },
		{ label: '2k-3k', value: 2 },
		{ label: '3k-4k ', value: 3 },
		{ label: '4k以上', value: 4 },
	],
	recruitWorkExperience: [ // 招聘工作经验
		{ label: '不限', value: 1 },
		{ label: '1年以内', value: 2 },
		{ label: '3-5年', value: 3 },
		{ label: '5-10年', value: 4 },
		{ label: '10年以上', value: 4 },
	],
	recruitWorkExperience: [ // 招聘工作经验
		{ label: '不限', value: 1 },
		{ label: '1年以内', value: 2 },
		{ label: '3-5年', value: 3 },
		{ label: '5-10年', value: 4 },
		{ label: '10年以上', value: 4 },
	],
	recruitIndustry: [ // 招聘公司行业
		{ label: '传媒', value: 1 },
		{ label: '电子', value: 2 },
		{ label: '纺织服务', value: 3 },
		{ label: '机械/维修', value: 4 },
		{ label: '计算机', value: 5 },
		{ label: '家电/家居', value: 6 },
		{ label: '交通运输', value: 7 },
		{ label: '教育培训', value: 8 },
		{ label: '金融', value: 9 },
		{ label: '能源', value: 10 },
		{ label: '日化', value: 11 },
		{ label: '农技', value: 12 },
		{ label: '汽车', value: 13 },
		{ label: '商贸零售', value: 14 },
		{ label: '生活服务', value: 15 },
		{ label: '食品加工', value: 16 },
		{ label: '通信', value: 17 },
		{ label: '医药服务', value: 18 },
		{ label: '畜牧兽医', value: 19 },
		{ label: '其他', value: 20 },
	],
	assessmentReviewStatus: [ // 评估审核状态
		{ label: '待评估', value: 1 },
		{ label: '已评估', value: 2 },
		{ label: '已拒绝', value: 3 },
		{ label: '取消评估', value: 4 }
	],
	priceUnit: [ // 评估单位  挂牌单位
		{ label: '元', value: 1 },
		{ label: '元/年', value: 2 },
		{ label: '元/亩/年', value: 3 },
		{ label: '元/亩', value: 4 },
		{ label: '元/平方米/年', value: 5 },
		{ label: '元/平方米/天', value: 6 },
		{ label: '元/米/年', value: 7 }
	],
	userType: [ // 用户类型
		{ label: '管理员', value: 1 },
		{ label: '普通用户', value: 2 },
		{ label: '银行机构', value: 3 },
		{ label: '政府机构', value: 4 },
		{ label: '集体用户', value: 5 },
		{ label: '评估机构', value: 6 }
	],
	supplyAuditStatus: [ // 供需管理-供应求购审核状态
		{ label: '待审核', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '审核拒绝', value: 3 }
	],
	supplyStatus: [ // 供需管理-供应状态
		{ label: '待成交', value: 1 },
		{ label: '已成交', value: 2 },
	],
	voteAuditStatus: [ // 投票审核状态
		{ label: '待审', value: 1 },
		{ label: '通过', value: 2 },
		{ label: '驳回', value: 3 },
		{ label: '删除', value: 4 },
		{ label: '取消', value: 5 }
	],
	publicityOfVillageAffairsAuditStatus: [ // 村务审核状态
		{ label: '待审', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '驳回', value: 3 }
	],
	villageProductAuditStatus: [ // 一村一品审核状态
		{ label: '待审', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '驳回', value: 3 }
	],
	investmentNodeStatus: [ // 招商项目节点状态
		{ label: '待审核', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '驳回', value: 3 }
	],
	// 托管管理
	custodyReviewStatus: [ // 托管审核状态
		{ label: '待审核', value: 1 },
		{ label: '初审通过', value: 2 },
		{ label: '初审驳回', value: 3 },
		{ label: '复审通过', value: 4 },
		{ label: '复审驳回', value: 5 },
		{ label: '平台审核通过', value: 6 },
		{ label: '平台审核驳回', value: 7 }
	],
	// 托管管理 - 托管流转状态
	custodyTransferStatus: [ // 托管流转状态
		{ label: '未发起', value: 1 },
		{ label: '待审核', value: 2 },
		{ label: '已通过', value: 3 },
		{ label: '流转中', value: 4 },
		{ label: '已驳回', value: 5 }
	],
	// 托管管理 - 托管流转状态
	custodyAcceptCommission: [ // 受托管理流转状态
		{ label: '未发起', value: 1 },
		{ label: '已发起', value: 2 }
	],
	// 托管管理 - 托管流转审核状态
	custodyTransferReviewStatus: [ // 托管流转审核状态
		{ label: '未发起', value: 1 },
		{ label: '待审核', value: 2 },
		{ label: '审核通过', value: 3 },
		{ label: '审核驳回', value: 4 }
	],
	// 托管管理 - 代偿审核状态
	compensationStatus: [ // 代偿审核状态
		{ label: '未发起', value: 1 },
		{ label: '初审中', value: 2 },
		{ label: '待复审', value: 3 },
		{ label: '复审中', value: 4 },
		{ label: '已通过', value: 5 },
		{ label: '已划转', value: 6 },
		{ label: '已驳回', value: 7 }
	],
	// 托管管理 - 托管评估状态
	custodyEvaluationStatus: [ // 托管评估状态
		{ label: '未发起', value: 1 },
		{ label: '待审核', value: 2 },
		{ label: '招标中', value: 3 },
		{ label: '招标完成', value: 4 },
		{ label: '评估中', value: 5 },
		{ label: '已完成', value: 6 },
		{ label: '已驳回', value: 7 }
	],
	// 标的用途
	subjectMatterPurpose : [
		{ label: '耕地', value: 1 },
		{ label: '林地', value: 2 },
		{ label: '园地', value: 3 },
		{ label: '机动地', value: 4 },
		{ label: '四荒地', value: 5 },
		{ label: '其他', value: 6 }
	],
	// 标的土地性质
	subjectMatterLandNature : [
		{ label: '集体所有', value: 1 },
		{ label: '国家所有', value: 2 }
	],
	// 标的类型
	subjectMatterType : [
		{ label: '建筑物', value: 1 },
		{ label: '房屋', value: 2 },
		{ label: '苗', value: 3 },
		{ label: '股权', value: 4 },
		{ label: '种畜禽', value: 5 },
		{ label: '交通运输工具', value: 6 },
		{ label: '商铺', value: 7 },
		{ label: '其他', value: 8 },
		{ label: '农用地', value: 9 },
		{ label: '建设用地', value: 10 },
		{ label: '未利用地', value: 11 }
	],
	// 抵押物规模单位
	scaleUnitOfCollateral: [
		{ label: '公顷', value: 1 },
		{ label: '平方米', value: 2 },
		{ label: '千克', value: 3 },
		{ label: '吨', value: 4 },
		{ label: '股', value: 5 },
		{ label: '套', value: 6 },
		{ label: '件', value: 7 },
		{ label: '台', value: 8 },
		{ label: '项', value: 9 },
		{ label: '亩', value: 10 },
	],
	// 是否完善过证书
	improvedCertificate: [
		{ label: '待申请', value: 1 },
		{ label: '待审核', value: 2 },
		{ label: '已审核', value: 3 },
	],
	// 要素普查上传文件类型
	generalSurveyFileType: [
		{ label: '产业服务配套要素服务', value: 1 },
		{ label: '文旅产业资源', value: 2 },
		{ label: '闲置资产', value: 3 },
		{ label: '资源性资产-养殖', value: 4 },
		{ label: '资源性资产-种植', value: 5 }
	],
	// 要素普查上传文件审核状态
	generalSurveyDetailStatus: [
		{ label: '待审核', value: 1 },
		{ label: '审核通过', value: 2 },
		{ label: '驳回', value: 3 }
	],
	// 数字认证审核状态
	digitalAuthenticationStatus: [
		{ label: '待评估', value: 1 },
		{ label: '待平台审核', value: 3 },
		{ label: '待发证', value: 4 },
		{ label: '申请已驳回', value: 5 },
		{ label: '已发证', value: 6 },
		{ label: '评估结果驳回', value: 7 },
		{ label: '评估结果待审核', value: 8 },
		{ label: '银行审核评估通过', value: 9 }
	],
	// 要素审核状态
	elementAuditingReviewStatus: [
		{ label: '待审核', value: 1 },
		{ label: '通过', value: 2 },
		{ label: '申请已驳回', value: 3 },
		{ label: '平台驳回', value: 4 }
	],
}
