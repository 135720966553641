import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import home from './home'; // 首页
import circulation from './circulationon' // 要素流转
import financing from './financing' // 要素融资
import collectiveEconomy from './collectiveEconomy' // 集体经济
import projectCooperation from './projectCooperation' // 项目合作
import dataKanban from './dataKanban' // 大数据看板
import announcement from './announcement' // 公告中心
import businessGuide from './businessGuide' // 业务指南
import industryAlliance from './industryAlliance' // 产业联盟
import trustCenter from './trustCenter' // 托管中心
import villageProduct from './villageProduct' // 一村一品
import my from './my'; // 个人中心
import bindding from './bidding'; // 个人中心

const children = [
	...home,
	...circulation,
	...financing,
	...collectiveEconomy,
	...projectCooperation,
	...announcement,
	...businessGuide,
	...industryAlliance,
	...trustCenter,
	...villageProduct,
	...my,
	...bindding
];

// 获取原型对象push函数
const originalPush = Router.prototype.push
// 获取原型对象replace函数
const originalReplace = Router.prototype.replace
// 修改原型对象中的push函数
Router.prototype.push = function push(location){
	return originalPush.call(this , location).catch(err=>err)
}
// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location){
	return originalReplace.call(this , location).catch(err=>err)
}

export default new Router({
	mode: 'hash',
	// base: process.env.NODE_ENV == 'production' ? 'demo-registry' : 'demo-registry',
	routes: [{
			path: '/',
			redirect: '/home'
		},
		{
			path: '/login',
			name: 'login',
			// component: () => import('../components/login.vue'),
			component: function (resolve) {
				require(['../views/login.vue'], resolve)
			}
		},
		{
			path: '/register',
			name: 'register',
			// component: () => import('../components/login.vue'),
			component: function (resolve) {
				require(['../views/register.vue'], resolve)
			}
		},
		...dataKanban,
		{
			path: '/',
			name: 'index',
			// component: () => import('../components/index.vue'),
			component: function (resolve) {
				require(['../views/index.vue'], resolve)
			},
			children: [
				...children, {
					path: '*',
					name: 'microApp',
					component: function (resolve) {
						require(['../views/microApp.vue'], resolve)
					}
				}
			],
		},
	],
})