<template>
	<div :style="hideToolbar ? '' : 'border: 1px solid #DCDFE6;'">
		<Toolbar
			v-if="!hideToolbar"
			style="border-bottom: 1px solid #DCDFE6"
			:editor="editor"
		/>
		<Editor
			:style="hideToolbar ? '' : 'height: 300px; overflow-y: hidden;'"
			:defaultConfig="editorConfig"
			v-model="content"
			@onCreated="onCreated"
		/>
	</div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
	name: "editor",
	props: ['value', 'readonly', 'hideToolbar'],
	model: {
		prop: 'value',
		event: 'change'
	},
	data() {
		return {
			content: '',
			editor: null,
			editorConfig: {
				MENU_CONF: {
					uploadImage: {
						fieldName: 'file',
						server: (process.env.NODE_ENV == 'production' ? process.env.VUE_APP_BASE_URL : '/api') + '/anony/save',
						customInsert: (res, insertFn) => {                  // JS 语法
							insertFn(this.$store.state.imgUrl + res.data, '', '');
						},
					},
				},
				autoFocus: false,
				readOnly: false
			}
		}
	},
	components: {
		Editor,
		Toolbar,
	},
	beforeUnmount() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},
	watch: {
		value() {
			this.content = this.value;
		},
		content() {
			this.$emit('change', this.content);
		},
		readOnly() {
			this.editorConfig.readOnly = this.readonly;
		}
	},
	methods: {
		onCreated(editor) {
			this.editor = Object.seal(editor);
		},
	},
	created() {
		this.content = this.value;
		this.editorConfig.readOnly = this.readonly;
	},
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
img{
	cursor: pointer;
	display: block;
}
</style>