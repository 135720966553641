
export default [
	{
		path: 'projectCooperation',
		name: 'projectCooperation',
		component: function (resolve) {
			require(['../views/projectCooperation/index.vue'], resolve)
		},
	},
	{
		path: 'projectCooperationDetail', // 招商、项目合作详情页
		name: 'projectCooperationDetail',
		component: function (resolve) {
			require(['../views/projectCooperation/detail.vue'], resolve)
		}
	},
]