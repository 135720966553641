
export default [
	{
		path: 'villageProduct', // 一村一品
		name: 'villageProduct',
		component: function (resolve) {
			require(['../views/villageProduct/villageProduct.vue'], resolve)
		}
	},
	{
		path: 'villageProductDetail', // 一村一品详情
		name: 'villageProductDetail',
		component: function (resolve) {
			require(['../views/villageProduct/villageProductDetail.vue'], resolve)
		}
	}
]