// 转出方式
export const transferOut = {
  1: '发包',
  2: '转让',
  3: '入股',
  4: '转包',
  5: '互换',
  6: '出租',
  7: '其他'
}

// 交易方式
export const tradingType = {
  1: '竞价',
  2: '拍卖',
  3: '协议',
  4: '招标'
}

// 支付方式
export const paymentWay = {
  // 1: '一次性支付全部交易价款',
  // 2: '分期支付',
  // 3: '其他'
  0: '分期支付',
  1: '全额支付',
}

// 担保类型
export const guaranteeTypes = {
  1: '信用',
  2: '抵押',
  3: '保证',
  4: '质押',
  5: '组合',
}

// 担保方式
export const guaranteeTypes1 = [
  {
    name: "全部",
    value: "",
  },
  {
    name: "信用",
    value: 1,
  },
  {
    name: "抵押",
    value: 2,
  },
  {
    name: "保证",
    value: 3,
  },
  {
    name: "质押",
    value: 4,
  },
  {
    name: "组合",
    value: 5,
  },
]

// 项目状态
export const projStatus = {
  // 1: '待审核',
  // 3: '审核驳回',
  2: '未开始',
  4: '报名中',
  5: '待成交',
  6: '已结束',
  7: '已成交',
  // 9: '已取消',
  8: '已公告中标'
}

// 报名状态
export const registerStatus = {
  2: '未开始',
  4: '报名中',
  7: '已成交'
}

// 价格单位
export const listPriceUnits = {
  1: '元',
  2: '元/年',
  3: '元/亩/年',
  4: '元/亩',
  5: '元/平方米/年',
  6: '元/平方米/天',
  7: '元/米/年'
}

//所有权人类型
export const ownerTypes = {
  1: '村集体',
  2: '国有',
  3: '集体',
  4: '合作社',
  5: '自然人',
}

// 出让方类型
export const assignorTypes = {
  1: '个人',
  2: '企业',
  3: '村集体',
  4: '其他类经营主体'
}

// 再次转出方式
export const transferOutAgain = {
  1: '转让',
  2: '入股',
  3: '转包',
  4: '互换',
  5: '出租'
}

// 缴纳形式
export const payment = {
  1: '现金汇款',
  2: '支票',
  3: '汇票',
  4: '电汇'
}

// 挂牌期满处理方式
export const expiry = {
  1: '信息发布终结',
  2: '延长发布信息',
  3: '变更公告内容重新挂牌'
}

//竞价方式 1累计加价 2自由竞价
export const biddingMethod = {
  1: '累计加价',
  2: '自由竞价',
}

//最高额度
export const maxQuota = {
  1: "50万元以下",
  2: "50-300万元",
  3: "300万以上",
};

//申请人类型
export const applyPeopleTypeList= 
{
  1: "个人",
  2: "企业",
  3: "村集体",
  4: "新型农业经营主体",
};

// 标的类型
export const targeTypes = {
  1: "建筑物",
  2: "房屋",
  3: "苗",
  4: "股权",
  5: "种畜禽",
  6: "交通运输工具",
  7: "商铺",
  8: "其他",
};
// 资产权益
export const assetEquity = {
  1: "使用权",
  2: "所有权",
  3: "租赁权",
  4: "收益权",
};

// 数量单位
export const quantityUnit = {
  11: '辆',
  12: '米',
  13: '个',
  2: '平方米',
  14: '眼',
  10: '亩',
  15: '口',
  6: '套',
  16: '间',
  22: '棵',
  17: '盏',
  8: '台',
  18: '斤',
  19: '公斤',
  20: '头',
  21: '座'
};
