
export default [
	// 业务指南
	{
		path: 'businessGuide',
		name: 'businessGuide',
		component: function (resolve) {
			require(['../views/businessGuide/index.vue'], resolve)
		},
	},
	{
		path: 'businessGuideDetail',
		name: 'businessGuideDetail',
		component: function (resolve) {
			require(['../views/businessGuide/detail.vue'], resolve)
		},
	},
]