export default [
	{
		path: 'circulation',
		name: 'circulation',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/index.vue'], resolve)
		},
	},
	{
		// 溯源
		path: 'traceability',
		name: 'traceability',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/traceability.vue'], resolve)
		},
	},
	{
		//项目详情
		path: 'proDetail',
		name: 'proDetail',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/proDetail.vue'], resolve)
		},
	},
	{
		//公告详情
		path: 'noticeDetail/:anncType([\\w\\W]+)',
		name: 'noticeDetail',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/noticeDetail.vue'], resolve)
		},
	},
	// 项目报名
	{
		path: 'signUp',
		name: 'signUp',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/signUp.vue'], resolve)
		},
	},
	{
		path: 'signUpData',
		name: 'signUpData',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/circulation/signUpData.vue'], resolve)
		},
	}
]