import { render, staticRenderFns } from "./editor.vue?vue&type=template&id=f99eee16&scoped=true"
import script from "./editor.vue?vue&type=script&lang=js"
export * from "./editor.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./editor.vue?vue&type=style&index=1&id=f99eee16&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f99eee16",
  null
  
)

export default component.exports