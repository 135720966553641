<template>
<div v-if="show" flex="dir:top" style="min-height: 100%;">
<!-- <div flex="dir:top"> -->
	<router-view />

	<!-- <div v-show="showRightBar" class="right-bar" flex="dir:top cross:center">
		<div flex="dir:top cross:center">
			<img src="./assets/imgs/index/right-icon1.png" />
			<p>搜索</p>
		</div>
		<div flex="dir:top cross:center">
			<img src="./assets/imgs/index/right-icon2.png" />
			<p>我要留言</p>
		</div>
		<div flex="dir:top cross:center">
			<img src="./assets/imgs/index/right-icon3.png" />
			<p>投诉建议</p>
		</div>
		<div flex="dir:top cross:center">
			<img src="./assets/imgs/index/right-icon4.png" />
			<p>安卓APP</p>
		</div>
		<div flex="dir:top cross:center">
			<img src="./assets/imgs/index/right-icon5.png" />
			<p>微信公众号</p>
		</div>
	</div> -->
</div>
</template>

<script>
import { initGlobalState } from 'qiankun';

let actions = initGlobalState({token: ''});

actions.onGlobalStateChange((state, prev) => {
	// state: 变更后的状态; prev 变更前的状态
	console.log('initGlobalState', state, prev);
});

export default {
	name: 'app',
	data() {
		return {
			show: false,
			scaleSize: 1,
			// showRightBar: false
		}
	},
	watch: {
		'$store.state.token'() {
			actions.setGlobalState({token: this.$store.state.token});
		},
		$route(to, from) {
			// if(to.matched[0].path == '/dataKanban' || to.matched[0].path == '/login' || to.matched[0].path == '/register') {
			// 	this.showRightBar = false;
			// }else {
			// 	this.showRightBar = true;
			// };
		},
	},
	created(){
		if(this.$store.state.token) {
			actions.setGlobalState({token: this.$store.state.token});
		}

		// 自适应
		// this.scaleSize = window.innerWidth / 1920;
		// window.addEventListener('resize', () => {
		// 	this.scaleSize = window.innerWidth / 1920;
		// });

		// 自适应
		document.getElementsByTagName('body')[0].style.zoom = window.innerWidth / 1920;
		window.addEventListener('resize', () => {
			document.getElementsByTagName('body')[0].style.zoom = window.innerWidth / 1920;
		});

		this.getFinancingTerm();
	},
	methods: {
		getFinancingTerm() {
			this.$ajax({
				url: 'anony/sys/dictionary/list',
				data: {
					parentId: 439
				},
				method: 'get',
				success: res => {
					let list = [];
					res.data.forEach(item => {
						list.push({
							label: item.name,
							value: item.value
						})
					})

					this.$store.state.financingTerm = list;
					this.getTextConfig();
				}
			})
		},
		getTextConfig() {
			this.$ajax({
				url: '/anony/cg/region/configuration/detail',
				method: 'get',
				success: res => {
					window.document.title = res.data.blockchainUser;
					
					let systemType = '';
					if(Number(res.data.regionCode) == 350982000000) {
						systemType = '福鼎市';
						// systemType = '冷水滩区';
					}
					if(Number(res.data.regionCode) == 431103000000) {
						systemType = '冷水滩区';
					}

					this.$store.state.systemType = systemType;
					this.$store.state.textConfig = res.data;
					
					this.show = true;
				},
				error: () => {
					this.show = true;
				}
			})
		}
	}
}
</script>
<style scoped lang="scss">
	.right-bar {
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		padding: 14px 0;
		width: 78px;
		background: #2675CD;
		right: 0;
		z-index: 111;

		div {
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			color: #fff;
			line-height: 12px;
			padding: 14px 0;
			cursor: pointer;

			img {
				padding-bottom: 13px;
			}
		}
	}
</style>
