<template>
	<div>
		<transition name="popup">
			<div class="mask" v-show="showSignBox">
				<div class="sign-box" flex="dir:top">
					<i @click="$emit('update:showSignBox', false)" class="iconfont icon-close"></i>
					<div flex-box="1">
						<canvas ref="signBox" width="758" height="382"></canvas>
					</div>
					<div flex-box="0" class="btn" flex="main:right">
						<div class="btn-big bg-4d99ed" @click="signConfirm()">提交</div>
						<div class="btn-big bg-ff9600" @click="clearSign()">清除</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "signBox",
	props: {
		value: {},
		showSignBox: {
			type: Boolean,
			dufault: false
		}
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	watch: {
		showSignBox() {
			this.clearSign();
		}
	},
	data() {
		return {
			
		}
	},
	mounted() {
		this.createdSing();
	},
	methods: {
		// 签名确认
		signConfirm() {
			// this.canvas.toDataURL();
			this.$ajax({
				url: "/anony/uploadBase64ToServer",
				data: {
					files: this.canvas.toDataURL(),
					dicName: "my",
				},
				success: (res) => {
					this.$emit('update:showSignBox', false);
					this.$emit('change', res.data);
					this.$emit('callBack');
				},
			});
		},
		// 清空签名
		clearSign() {
			this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
		},
		// 创建签名方法
		createdSing() {
			let canvas = (this.canvas = this.$refs.signBox);
			let cxt = (this.cxt = canvas.getContext("2d"));
			cxt.fillStyle = "#fff";
			cxt.fillRect(0, 0, canvas.width, canvas.width);
			cxt.strokeStyle = "#000";
			cxt.lineWidth = 2;
			cxt.lineCap = "round";

			//开始绘制
			canvas.addEventListener("mousedown", function (e) {
				cxt.beginPath();
				cxt.moveTo(e.offsetX, e.offsetY);

				//绘制中
				let move = (e) => {
					cxt.lineTo(e.offsetX, e.offsetY);
					cxt.stroke();
				};
				canvas.addEventListener("mousemove", move);

				//结束绘制
				document.addEventListener("mouseup", function () {
					canvas.removeEventListener("mousemove", move);
					cxt.closePath();
				});
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/styles/input.scss';
@import '@/styles/table.scss';
@import '@/styles/btn.scss';
.sign-box {
	position: absolute;
	height: 500px;
	width: 820px;
	background: #ffffff;
	border-radius: 5px;
	left: 50%;
	top: 50%;
	margin-left: -410px;
	margin-top: -240px;
	padding: 30px;

	.icon-close {
		font-size: 14px;
		position: absolute;
		padding: 5px;
		cursor: pointer;
		right: 23px;
		top: 25px;
		color: #a7aeb8;
	}

	.btn {
		padding-top: 20px;

		div {
			cursor: pointer;
			margin-left: 15px;
			margin-bottom: 15px;
		}
	}
}
</style>