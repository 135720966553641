export default [
	{
		path: '/dataKanban',
		name: 'dataKanban',
		redirect: '/dataKanban/financing',
		// component: () => import('../components/index.vue'),
		component: function (resolve) {
			require(['../views/dataKanban/index.vue'], resolve)
		},
		children: [
			{
				path: 'financing',
				name: 'datakanbanFinancing',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/financing.vue'], resolve)
				}
			},
			{
				path: 'circulation',
				name: 'datakanbanCirculation',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/circulation.vue'], resolve)
				}
			},
			{
				path: 'trust',
				name: 'datakanbanTrust',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/trust.vue'], resolve)
				}
			},
			{
				path: 'collectiveEconomy',
				name: 'datakanbanCollectiveEconomy',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/collectiveEconomy.vue'], resolve)
				}
			},
			{
				path: 'industryResources',
				name: 'datakanbanIndustryResources',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/industryResources.vue'], resolve)
				}
			},
			{
				path: 'ruralCulturalTourism',
				name: 'datakanbanRuralCulturalTourism',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/ruralCulturalTourism.vue'], resolve)
				}
			},
			{
				path: 'ecologicalResources',
				name: 'datakanbanEcologicalResources',
				// component: () => import('../components/index.vue'),
				component: function (resolve) {
					require(['../views/dataKanban/ecologicalResources.vue'], resolve)
				}
			},
		],
	}
]